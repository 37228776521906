@tailwind base;
@tailwind utilities;
@tailwind components;

:root {
    --foreground: #000000;
    --page-background: theme('colors.primary.50');
    --background: #ffffff;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground: #ffffff;
        --page-background: theme('colors.primary.950');
        --background: #000000;
    }
}

body {
    color: var(--foreground);
    background: var(--page-background);

    @apply font-sans antialiased text-base
}

.inline-link {
    @apply text-primary-500 underline
}